<template>
  <AdminSingleCard v-if="mode === 'resetPassword'">
    <template v-slot:title>
      <p class="reset-password-title">パスワード再設定画面</p>
    </template>

    <template v-slot:description>
      <p class="reset-password-description">
        新しいパスワードを入力し送信ボタンを押してください。
      </p>
    </template>

    <template v-slot:contents>
      <p class="reset-password-password-title">新しいパスワード</p>
      <input class="password" type="password" v-model="password" />

      <p class="reset-password-password-confirm-title">新しいパスワード（確認用）</p>
      <input class="password" type="password" v-model="passwordConfirm"/>
    </template>

    <template v-slot:button>
      <div class="button-wrapper">
        <WideButton
          :label="'パスワード再設定'"
          :disabled="disabled"
          @click="$_resetPassword"
        />
        <WideButton
          :label="'ログインに戻る'"
          :backgroundColor="'#707070'"
          @click="$router.push('/admin/login')"
        />
      </div>
    </template>
  </AdminSingleCard>
</template>

<script>
import WideButton from '@/components/atoms/common/AppWideButton'
import AdminSingleCard from '@/templates/admin/AppAdminSingleCard'
import { showInfoPopupAlert, showErrorPopupAlert } from '@/helper/common'
import { getUserByEmail } from '@/helper/firestore/user'
import { USER_TYPE } from '@/constants/common'
import {
  auth,
  verifyPasswordResetCode,
  confirmPasswordReset,
  applyActionCode
} from '@/firebase/config'

/**
 * FIXME: パスワード再設定とメールアドレス承認ページがこのコンポーネントにルーティングされる
 * Firebaseの仕様（アクションURLがひとつしか指定できない）なので仕方ないが、本コンポーネントは
 * 責務ごとに明確になるよう理ファクタする必要がある
 */
export default {
  name: 'TheResetPassord',
  components: {
    AdminSingleCard,
    WideButton
  },
  data: function () {
    return {
      password: '',
      passwordConfirm: '',
      message: '',
      messageType: '',
      callback: null,
      mode: '',
      actionCode: ''
    }
  },
  watch: {
    message: function () {
      if (this.messageType === 'info') {
        showInfoPopupAlert(
          this.message,
          true,
          {},
          this.callback
        )
      } else if (this.messageType === 'error') {
        showErrorPopupAlert(this.message)
      }
    }
  },
  async mounted() {
    this.mode = this.$_getParameterByName("mode")
    this.actionCode = this.$_getParameterByName("oobCode")
    // メールリンクログインも同じページに来るので切り分け
    if (this.mode === 'verifyEmail') {
      // メールアドレス認証をtrueに更新
      await applyActionCode(auth, this.actionCode)
      this.$router.push(
        {
           path: '/user/login',
           query: { registrationCompleted: true }
         }
      )
    }
  },
  computed: {
    disabled() {
      return (
        this.password &&
        this.passwordConfirm &&
        (this.password !== this.passwordConfirm)
      )
    }
  },
  methods: {
    $_getParameterByName(name) {
      const url = window.location.href
      const cleanName = name.replace(/\[\]/g, "\\$&")
      const regex = new RegExp("[?&]" + cleanName + "(=([^&#]*)|&|#|$)")
      const results = regex.exec(url)
      if (!results) return null
      if (!results[2]) return ''
      return decodeURIComponent(results[2].replace(/\+/g, " "))
    },
    $_resetPassword: async function () {
      this.$store.dispatch('loadingMask/showLoadingMask')
      try {
        const email = await verifyPasswordResetCode(auth, this.actionCode)
        await confirmPasswordReset(auth, this.actionCode, this.password)
        // ログインしていないので、auth.currentUserは取れない → メールアドレスでUserのタイプを引く
        const user = await getUserByEmail(email)

        this.messageType = "info"
        this.message = 'パスワードを再設定しました。'

        user.length && user[0].type === USER_TYPE.USER.ID
          ? this.$router.push('/user/login')
          : this.$router.push('/admin/login')
      } catch (error) {
        this.messageType = "error"
        this.$_setErrorMessage(error.message)
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    },
    $_setErrorMessage(message) {
      if (message.includes("expired-action-code")){
        this.message = "パスワードリンクの有効期限が切れました。<br />再度パスワード再設定メールを送信してください。"
      } else if (message.includes("invalid-action-code")){
        this.message = "パスワードリンクが不正です。<br />再度パスワード再設定メールを送信してください。"
      } else if (message.includes("user-disabled")){
        this.message = "対象アカウントが無効化されています。<br />管理者にお問い合わせください。"
      } else if (message.includes("user-not-found")){
        this.message = "入力されたメールアドレスに一致するアカウントはみつかりませんでした。"
      } else {
        this.message = message
      }
    }
  },
}
</script>

<style lang="scss">
.reset-password-description {
  margin: 32px 0 16px 0;
}
input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  border: 1px solid gray;
  border-radius: 4px;
}
.reset-password-title {
  margin: 16px 0;
}
.reset-password-password-title {
  text-align: left;
  margin-bottom: 8px;
  font-size: 12px;
}
.reset-password-password-confirm-title {
  text-align: left;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 12px;
}
.password {
  margin: 0;
}
.button-wrapper {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
</style>
